import React from "react";
import { LinksDetails } from "./LinksDetails";
import { LinksDropdown } from "./LinksDropdown";

export const LinksEditor = () => {
  return (
    <>
      <LinksDropdown />
      <LinksDetails />
    </>
  );
};


