module.exports = function(obj){
var __t,__p='',__j=Array.prototype.join,print=function(){__p+=__j.call(arguments,'');};
with(obj||{}){
__p+='  <div class="application-detail-view">\n    ';
 var visible = " visibility: hidden; ";
__p+='\n    ';
 if (installed) { 
__p+='\n    ';
   visible = " visibility: visible; ";
__p+='\n    ';
 } 
__p+='\n\n    <div class="headerBar" style="width: 100%">\n      <ul class="subMenuEntries subViewNavbar pull-left" style="'+
((__t=(visible))==null?'':__t)+
'">\n        <li id="service-info" class="subMenuEntry active"><a>Info</a></li>\n        <li id="service-api" class="subMenuEntry "><a>API</a></li>\n        <!--<li id="service-readme" class="subMenuEntry "><a>Readme</a></li>-->\n        <li id="service-settings" class="subMenuEntry "><a>Settings</a></li>\n      </ul>\n\n      <div class="headerButtonBar" style="display: none">\n        <ul class="headerButtonList">\n          <li>\n            <a id="app-deps" class="headerButton '+
((__t=( _.isEmpty(app.get('deps')) ? 'disabled' : '' ))==null?'':__t)+
' '+
((__t=( app.hasUnconfiguredDependencies() ? 'error' : '' ))==null?'':__t)+
'">\n              <span class="fa fa-cubes" title="Dependencies"></span>\n            </a>\n          </li>\n          <li id="app-scripts" class="dropdown">\n            <a class="headerButton '+
((__t=( _.isEmpty(app.get('scripts')) ? 'disabled' : '' ))==null?'':__t)+
'" title="Scripts">\n              <i class="fa fa-code"></i>\n            </a>\n            <ul class="script-dropdown-menu dropdown-toolbar dropdown-menu" id="scripts_dropdown">\n            ';
 _.each(app.get('scripts'), function (title, name) { 
__p+='\n              <li class="dropdown-item">\n                <a class="tab" data-script="'+
((__t=( name ))==null?'':__t)+
'">'+
((__t=( title ))==null?'':__t)+
'</a>\n              </li>\n            ';
 }) 
__p+='\n            </ul>\n          </li>\n          <li>\n            <a id="app-tests" class="headerButton">\n              <span class="fa fa-flask" title="Tests"></span>\n            </a>\n          </li>\n          <li>\n            <a id="download-app" class="headerButton '+
((__t=( app.isSystem() ? 'disabled' : '' ))==null?'':__t)+
'">\n              <span class="fa fa-download" title="Download"></span>\n            </a>\n          </li>\n        </ul>\n      </div>\n    </div>\n\n    <section class="info" id="informationfunctions" style="display: none">\n      <div class="header">\n        <div id="hidden_buttons" style="display: none">\n          <button class="delete button-danger" '+
((__t=(app.isSystem()?"disabled":""))==null?'':__t)+
' >Delete</button>\n          <button id="app-replace" class="app-replace upgrade button-warning" '+
((__t=(app.isSystem()?"disabled":"" ))==null?'':__t)+
'>Replace</button>\n          <button id="app-switch-mode" class="app-switch-mode switch-mode button-warning" '+
((__t=(app.isSystem()?"disabled":"" ))==null?'':__t)+
'>'+
((__t=(app.get('development')?'Set Production':'Set Development'))==null?'':__t)+
'</button>\n        </div>\n      </div>\n    </section>\n\n    <section class="info" id="information" style="width: 100%;">\n      <div class="pure-g">\n\n        <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-3-4 foxx-left">\n          <div class="foxx-icon-container pull-left">\n            <img src="'+
((__t=( app.thumbnailUrl ))==null?'':__t)+
'" alt="Icon for Service" class="icon" />\n          </div>\n          <div class="pull-left" style="margin-left: 15px">\n            <h3>\n              '+
((__t=( app.attributes.name ))==null?'':__t)+
'\n            </h3>\n            <p class="description">'+
((__t=( app.attributes.description ))==null?'':__t)+
'</p>\n          </div>\n          <div id="app-warning" class="app-warning" style="clear: both; display:'+
((__t=(app.needsAttention()?'block':'none' ))==null?'':__t)+
';">\n            <h4>Out of order</h4>\n            <p id="app-warning-broken" style="display:'+
((__t=(app.isBroken()?'block':'none' ))==null?'':__t)+
';">This service has failed to mount due to an error.</p>\n            <p id="app-warning-config" style="display:'+
((__t=(app.needsConfiguration()?'block':'none' ))==null?'':__t)+
';">This service has not yet been configured properly.</p>\n            <p id="app-warning-deps" style="display:'+
((__t=(app.hasUnconfiguredDependencies()?'block':'block' ))==null?'':__t)+
';">Some dependencies have not yet been set up.</p>\n          </div>\n           <div class="categoryBar">\n             <h4>Readme</h4>\n           </div>\n          <div id="readme" class="readme">\n            ';
 if (app.get('readme')) { 
__p+='\n              '+
((__t=( marked(app.get('readme')) ))==null?'':__t)+
'\n            ';
 } else { 
__p+='\n              <p>No README data found.</p>\n            ';
 } 
__p+='\n          </div>\n        </div>\n\n        <div class="pure-u-1 pure-u-sm-4-4 pure-u-md-1-4 foxx-right">\n          <div class="foxx-label-container">\n            <p>\n              <span>Author: </span><span>'+
((__t=( app.attributes.author ))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              <span>Mount: </span><span>'+
((__t=( app.attributes.mount ))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              <span>Mode: </span><span class="mode '+
((__t=(app.get('development')?'development':'production'))==null?'':__t)+
'">'+
((__t=(app.get('development')?'Development':'Production'))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              <span>Version: </span><span class="version">'+
((__t=( app.attributes.version ))==null?'':__t)+
'</span>\n            </p>\n            <p>\n              ';
 if (app.attributes.license) { 
__p+='\n                <span>License: </span><span>'+
((__t=( app.attributes.license ))==null?'':__t)+
'</span>\n              ';
 } 
__p+='\n            </p>\n            <p>\n              ';
 if (app.get('development') === true) { 
__p+='\n                <span>Path: </span><span class="developmentSpan">'+
((__t=( app.attributes.path ))==null?'':__t)+
'</span>\n              ';
 } else { 
__p+='\n                <span></span>\n              ';
 } 
__p+='\n            </p>\n          </div>\n          <div class="header_right">\n            <input type="button" class="open button-success" disabled="true" value="Show Interface" />\n          </div>\n        </div>\n\n      </div>\n    </section>\n\n    <main>\n      <div id="app-info" class="app-info" style="display: none;">\n        <h4>Info</h4>\n        <p id="app-info-swagger">Documentation for this service is not yet configured.</p>\n      </div>\n      <div id="swagger" class="swagger" style="display: none;">\n        <a id="jsonLink">Swagger</a>\n        <div id="swaggerJsonContent" style="display: none">\n          <div id="swaggerJsonEditor" style="height: 100%;"></div>\n        </div>\n      </div>\n      <div id="settings" class="readme" style="display: none; padding: 15px;">\n        ';
 if (_.isEmpty(app.get('config'))) { 
__p+='\n          <p>No configurable settings available.</p>\n        ';
 } 
__p+='\n        <div class="buttons" style="margin-left: -10px; margin-top: 20px;"></div>\n      </div>\n    </main>\n    <!--<aside class="meta" id="sideinformation">\n      <dl>\n        <dt>Author</dt>\n        <dd>'+
((__t=( app.attributes.author ))==null?'':__t)+
'</dd>\n        <dt>Contributors</dt>\n        <dd>\n          ';
 if (app.attributes.contributors && app.attributes.contributors.length > 0) { 
__p+='\n          <ul>\n          ';
 _.each(app.attributes.contributors, function (contributor) { 
__p+='\n            <li><a href="mailto:'+
((__t=( contributor.email ))==null?'':__t)+
'">'+
((__t=( contributor.name ))==null?'':__t)+
'</a></li>\n          ';
 }); 
__p+='\n          </ul>\n          ';
 } else { 
__p+='\n          No contributors\n          ';
 } 
__p+='\n        </dd>\n        <dt>Links</dt>\n        <dd>\n          <ul>\n            <li>\n              <a href="'+
((__t=( baseUrl ))==null?'':__t)+
'/_admin/aardvark/foxxes/docs/standalone/index.html?mount='+
((__t=( encodeURIComponent(app.get('mount')) ))==null?'':__t)+
'" target="_blank">\n                API Documentation\n              </a>\n            </li>\n            <li>\n              <a href="'+
((__t=( baseUrl ))==null?'':__t)+
'/_admin/aardvark/foxxes/docs/swagger.json?mount='+
((__t=( encodeURIComponent(app.get('mount')) ))==null?'':__t)+
'" target="_blank">\n                API as JSON\n              </a>\n            </li>\n            ';
 if (app.attributes.repository && app.attributes.repository.type == 'git') { 
__p+='\n              <li><a href="'+
((__t=( app.attributes.repository.url ))==null?'':__t)+
'" target="_blank">Git Repository</a></li>\n            ';
 } 
__p+='\n          </ul>\n        </dd>\n      </dl>\n    </aside>-->\n  </div>\n';
}
return __p;
};
